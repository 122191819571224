<template>
  <TepmplateBlock
    content-class="main-wrap pt-4 m-10"
    mod-class
    title-page="Коллеги и контакты"
  >
    <section class="colleagues-contacts">
      <h3 class="colleagues-contacts__info-company">
        {{ companyData && "brand" in companyData ? companyData.brand : "" }}
        <br />
        {{
          companyData && "fact_address" in companyData
            ? companyData.fact_address
            : ""
        }}
        <br />
        {{ companyData && "phone" in companyData ? companyData.phone : "" }}
      </h3>
      <div class="colleagues-contacts__panel m-2">
        <input
          class="colleagues-contacts__panel__search-panel"
          v-model="search"
          placeholder="Поиск"
          type="search"
        />
        <button
          @click="clickFunction"
          class="colleagues-contacts__panel__setting-privacy"
        >
          Настройка прав доступа
        </button>
      </div>
      <vuescroll>
        <div class="colleagues-contacts__cards-wrapper">
          <div
            class="colleagues-contacts__card"
            v-for="(user, index) of filteredGroupedCards"
            :key="index"
          >
            <div
              class="colleagues-contacts__card__edit"
              @click="goToEdit(user)"
            >
              <img src="../../resource/img/user-icon/edit.svg" alt="edit" />
            </div>
            <div class="colleagues-contacts__card__points">
              <img src="../../resource/img/user-icon/points.svg" alt="points" />
            </div>
            <span class="colleagues-contacts__card__icon">
              <img src="../../resource/img/user-icon/person.svg" />
            </span>
            <div class="colleagues-contacts__card__name">
              {{ user.first_name }} {{ user.last_name }}
            </div>
            <div class="colleagues-contacts__card__email" :id="user.email">
              {{ user.email | truncate(30, "...") }}
            </div>
            <b-popover
              custom-class="user-list__popover popover-notice"
              :target="user.email"
              triggers="hover focus"
              placement="right"
            >
              <div class="popoverEmail">{{ user.email }}</div>
            </b-popover>
            <div class="colleagues-contacts__card__position">
              {{ user.role }}
            </div>
            <div class="colleagues-contacts__card__phone">
              {{ user.phone_number }}
            </div>
            <div class="colleagues-contacts__card__button-group">
              <button
                class="colleagues-contacts__card__button-group__write-to-chat"
                @click="goToChat(user)"
              >
                Написать в чат
              </button>
              <button
                class="colleagues-contacts__card__button-group__create-task"
                @click="goToCreateOrder(user)"
              >
                Создать задачу
              </button>
            </div>
          </div>
        </div>
      </vuescroll>
    </section>
  </TepmplateBlock>
</template>

<script>
import TepmplateBlock from "@/components/TepmplateBlock";
import RequestManager from "@/function/request/RequestManager";
import vuescroll from "vuescroll";

export default {
  name: "ColleaguesContacts",
  components: {
    TepmplateBlock,
    vuescroll
  },
  data() {
    return {
      search: "",
      companyData: null,
      companyUsers: null,
      currentUser: null,
      editMode: false,
      textEmail: ""
    };
  },
  filters: {
    truncate: function(text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    }
  },
  computed: {
    filteredGroupedCards() {
      return this.companyUsers
        ? this.companyUsers.filter(el => {
            return (
              el.email?.toLowerCase().includes(this.search?.toLowerCase()) ||
              (
                el.first_name?.toLowerCase() +
                " " +
                el.last_name?.toLowerCase()
              ).includes(this.search?.toLowerCase())
            );
          })
        : null;
    }
  },
  methods: {
    clickFunction() {
      this.$router.push({
        name: "OfficesList"
      });
    },
    goToChat(user) {
      this.currentUser = user;
      this.$router.push({
        name: `Chats`,
        params: {
          dataUsers: this.currentUser
        }
      });
    },
    goToCreateOrder(user) {
      console.log("user", user);
      this.$router.push({
        name: "RequestCreate",
        params: { typeId: 5, id: user.id }
      });
      /* this.$router.push({
        name: "RequestCreate",
        params: {
          id: user.id
        }
      });*/
    },
    goToEdit(user) {
      this.$router.push({
        name: "EditUser",
        params: {
          id: user.id
        }
      });
    },
    validateEmail() {
      console.log(this.currentUser);
    }
  },
  async created() {
    const client = RequestManager();
    const userData = await client.getCurrentUser();
    const companyId = userData.company;
    this.companyData = await client.getCompanyInfo(companyId);
    this.companyUsers = this.companyData.users;
  },
  mounted() {
    this.search = decodeURI(location.hash.slice(1)) || "";
  }
};
</script>

<style lang="scss" scoped>
.colleagues-contacts {
  height: 100%;
  width: 100%;
  &__info-company {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 15px;
    letter-spacing: -0.333333px;
    color: var(--main-text-color);
    width: 310px;
    height: auto;
  }
  &__cards-wrapper {
    word-break: break-all;
    margin-top: 32px;
    margin-bottom: 20px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    grid-template-rows: repeat(auto-fit, minmax(30px, 1fr));
    justify-content: space-between;
    gap: 33px;
  }
  &__card__name {
    height: 2rem;
  }
  &__panel {
    display: flex;
    justify-content: space-between;
    margin-top: 42px;
    &__search-panel {
      width: 384px;
      height: 40px;
      left: 108px;
      top: 275px;
      border: 1px solid #b1b1b1;
      color: var(--main-text-color);
      border-radius: 4px;
      background: var(--color-for-searching) url("../../resource/img/lupa.svg")
        no-repeat 12px 7px;
      outline: none;
      padding: 0 12px 0 45px;
      :active {
        background: inherit;
      }
    }
    &__setting-privacy {
      margin-right: 6px;
      width: 157px;
      height: 46px;
      background: #2c698c;
      border-radius: 4px;
      font-style: normal;
      font-weight: 600;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      letter-spacing: -0.333333px;
      color: #ffffff;
      border: none;
    }
  }
  &__card {
    width: 150px;
    min-height: 269px;
    background: var(--main-card-color);
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 7px;
    position: relative;
    padding: 15px 15px 88px;
    &__edit {
      position: absolute;
      top: 12px;
      left: 9px;
      width: 10px;
      height: 10px;
      cursor: pointer;
    }
    &__points {
      position: absolute;
      top: 12px;
      right: 9px;
      cursor: pointer;
    }
    &__icon {
      display: block;
      text-align: center;
    }
    &__name {
      text-align: center;
      margin-top: 19px;
      color: var(--card-text-color);
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
    }
    &__email {
      cursor: pointer;
      margin-top: 11px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -0.3px;
      text-decoration-line: underline;
      color: var(--card-text-color);
    }
    &__position {
      margin-top: 6px;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -0.3px;
      color: #909090;
    }
    &__phone {
      margin-top: 7px;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 16px;
      text-align: center;
      letter-spacing: -0.333333px;
      color: #393952;
    }
    &__button-group {
      position: absolute;
      bottom: 15px;
      left: 50%;
      transform: translateX(-50%);
      &__write-to-chat {
        margin: 0 auto;
        display: block;
        width: 93px;
        height: 27px;
        background: #2c698c;
        border-radius: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        letter-spacing: -0.3px;
        border: none;
        color: #ffffff;
      }
      &__create-task {
        margin: 15px auto 0 auto;
        display: block;
        width: 93px;
        height: 27px;
        background: var(--main-card-color);
        border: 1px solid #2c698c;
        border-radius: 4px;
        font-style: normal;
        font-weight: 600;
        font-size: 10px;
        line-height: 12px;
        text-align: center;
        letter-spacing: -0.3px;
        color: #2c698c;
      }
    }
  }
}

.popoverEmail {
  padding: 5px 10px;
  cursor: pointer;
  background-color: var(--main-card-color);
  color: var(--main-text-color);
  position: relative;
  top: 0;
  right: 0;
}

.__vuescroll {
  height: calc(100vh - 240px) !important;
  width: 100% !important;
}
</style>
